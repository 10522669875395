import React, { useState } from 'react';
import { Descriptions, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router';

import { Offer as FidelOffer } from '@fidel.uk/types';
import { Scheme, StyledSpan } from '../styled';
import FieldLabel from './FieldLabel';
import {
  ContentProviderMetadata,
  Offer,
  OfferType,
} from '../../../../store/offers/offers-model';
import {
  amountWithUnit,
  isUniqueOffer,
  metadataHasProgramInfo,
  numOfAddedAndAvailablePrograms,
  parseChannels,
} from '../../utils';
import { LinkButton } from '../../../../components/buttons';
import { colors } from '../../../../theme';
import { WeekDay } from '../../../../types';
import { toWeekDays } from '../../../../utils/dates';
import { DOUBLE_MINUS } from '../../../../utils/special-character';
import { toDecimal, withCurrency } from '../../../../utils/transform';
import { DetailField } from '../types';
import { icons } from '../../../../utils/schemes';
import OfferTerms from '../../components/OfferTerms';
import LocationsDrawer from './LocationsDrawer';
import AvailableProgramsDrawer from './available-programs-drawer';
import { useAppSelector } from '../../../../store/hooks';
import { selectProgramsAsList } from '../../../../store/programs/programs-selectors';
import MidsDrawer from './MidsDrawer';
import { OaasMid } from '../../types';
import Spin from '../../../../components/spin';
import { currencyBySupportedCountries } from '../../../../utils/countries';

interface FieldsListProps {
  offer: Offer;
  offerMids: OaasMid[];
  midsLoading: boolean;
}

export default function FieldsList({
  offer,
  offerMids,
  midsLoading,
}: FieldsListProps) {
  const { type: offerType } = useParams<{ type: OfferType }>();

  const { t } = useTranslation(['common', 'offers']);
  const programsByCountry = useAppSelector(
    state => state.programs.programsByCountry,
  );

  const distroOnlyPrograms = useAppSelector(selectProgramsAsList)(
    'transaction-distro-only',
  );

  const [showOfferTerms, setShowOfferTerms] = useState(false);
  const [showOfferLocations, setShowOfferLocations] = useState(false);
  const [showOfferMids, setShowOfferMids] = useState(false);
  const [showAvailablePrograms, setShowAvailablePrograms] = useState(false);

  const {
    activation,
    additionalTerms,
    countryCode,
    currency,
    channels,
    daysOfWeek,
    endDate,
    locationsTotal,
    minTransactionAmount,
    type: { maxRewardAmount, value },
    returnPeriod,
    schemes,
    startDate,
    supplier,
    supplierId,
    metadata,
    requiresApproval,
    programName,
    publisherInfo,
    maxRedemptions,
    budget,
  } = offer;

  const { accountName, userEmail } = publisherInfo || {};

  const midsTotal = offerMids.length;

  const displayAmount = (amount: number) => (
    <>
      <span style={{ color: colors.grey4 }}>{currency}</span>{' '}
      {toDecimal(amount)}
    </>
  );

  const displayPeriodUnit = (period: number, unit: string) => {
    if (period === 1) return `${period} ${unit}`;

    return `${period} ${unit}s`;
  };

  const MidsCount = () =>
    midsTotal > 0 ? (
      <LinkButton
        type="link"
        style={{ fontSize: '14px' }}
        onClick={() => setShowOfferMids(true)}
        data-testid="mid-link"
      >
        {midsTotal}
      </LinkButton>
    ) : (
      <Typography.Text data-testid="mid-link">
        {midsTotal === 0 ? midsTotal : DOUBLE_MINUS}
      </Typography.Text>
    );

  const LocationsCount = () =>
    locationsTotal > 0 ? (
      <LinkButton
        type="link"
        style={{ fontSize: '14px' }}
        onClick={() => setShowOfferLocations(true)}
        data-testid="location-link"
      >
        {locationsTotal}
      </LinkButton>
    ) : (
      <Typography.Text data-testid="location-link">
        {locationsTotal === 0 ? locationsTotal : DOUBLE_MINUS}
      </Typography.Text>
    );

  const offerChannels = parseChannels(
    channels ??
      (metadata?.contentProvider as ContentProviderMetadata)?.payment?.channel,
  );
  const isMarketplaceOffer = isUniqueOffer(offer);

  const metadataProgramInfo = metadataHasProgramInfo(metadata)
    ? metadata.programInfo
    : undefined;

  const fields: DetailField[] = [
    {
      name: 'activation',
      content: t(`common:${activation?.enabled ? `required` : `notRequired`}`),
      showTooltip: true,
    },
    {
      name: 'returnPeriod',
      content: returnPeriod || DOUBLE_MINUS,
    },
    {
      name: 'reward',
      content: amountWithUnit(offer, value),
    },
    {
      name: 'country',
      content: t(`common:countries.${countryCode}`),
    },
    {
      name: 'locations',
      content: <LocationsCount />,
    },
    {
      name: 'oaasMids',
      content: midsLoading ? (
        <Spin data-testid="loading" style={{ padding: 0 }} />
      ) : (
        <MidsCount />
      ),
      hidden:
        offerType === 'marketplace' ||
        offer.transactionSource !== FidelOffer.OfferTransactionSource.OAAS,
    },
    {
      name: 'program',
      content:
        (programName || metadataProgramInfo?.programName) ?? DOUBLE_MINUS,
      hidden: offerType === 'marketplace',
    },
    {
      name: 'programs',
      content: (
        <LinkButton
          type="link"
          style={{ fontSize: '14px' }}
          onClick={() => setShowAvailablePrograms(true)}
          data-testid="programs-link"
        >
          {numOfAddedAndAvailablePrograms(
            offer,
            programsByCountry,
            distroOnlyPrograms,
          )}
        </LinkButton>
      ),
      hidden: offerType !== 'marketplace',
    },
    {
      name: 'minSpend',
      content: minTransactionAmount
        ? displayAmount(minTransactionAmount)
        : DOUBLE_MINUS,
      showTooltip: true,
    },
    {
      name: 'maxReward',
      content: maxRewardAmount ? displayAmount(maxRewardAmount) : DOUBLE_MINUS,
    },
    {
      name: 'maxRedemptions',
      content:
        maxRedemptions && maxRedemptions.period && maxRedemptions.unit
          ? `${maxRedemptions.transactionsLimit} | ${displayPeriodUnit(
              maxRedemptions.period,
              maxRedemptions.unit,
            )}`
          : DOUBLE_MINUS,
      hidden:
        offer.transactionSource !== FidelOffer.OfferTransactionSource.OAAS,
    },
    {
      name: 'budget',
      content:
        budget && budget.period && budget.unit
          ? `${withCurrency(
              budget.limit,
              currencyBySupportedCountries[countryCode].toString(),
              false,
            )} | ${displayPeriodUnit(budget.period, budget.unit)}`
          : DOUBLE_MINUS,
      hidden:
        offer.transactionSource !== FidelOffer.OfferTransactionSource.OAAS,
    },

    {
      name: 'startDate',
      content: moment(startDate).format('MMM DD, YYYY HH:mm'),
    },
    {
      name: 'endDate',
      content: endDate
        ? moment(endDate).format('MMM DD, YYYY HH:mm')
        : DOUBLE_MINUS,
    },
    {
      name: 'schedule',
      content:
        daysOfWeek?.length === 7
          ? t('offers:everyday')
          : toWeekDays(daysOfWeek)
              .map((day: WeekDay) => t(`common:days.${day}`).slice(0, 3))
              .join(', '),
    },
    {
      name: 'networks',
      content: schemes
        ? schemes?.map((scheme: string) => (
            <Scheme key={scheme}>
              <img
                alt={scheme}
                className={scheme}
                src={icons[scheme]}
                role="presentation"
              />
            </Scheme>
          ))
        : DOUBLE_MINUS,
    },
    {
      name: 'terms',
      content: additionalTerms ? (
        <LinkButton
          type="link"
          style={{ fontSize: '14px' }}
          data-testid="unique-offer-terms"
          onClick={() => setShowOfferTerms(true)}
        >
          {t('offers:offerTerms')}
        </LinkButton>
      ) : (
        DOUBLE_MINUS
      ),
    },
    {
      name: 'reportingProvider',
      content: isMarketplaceOffer ? DOUBLE_MINUS : supplier ?? DOUBLE_MINUS,
    },
    {
      name: 'channels',
      content: offerChannels,
      hidden: !isMarketplaceOffer,
    },
    {
      name: 'approval',
      content: t(`common:${requiresApproval ? 'required' : 'notRequired'}`),
      hidden: !isMarketplaceOffer,
      showTooltip: true,
    },
    {
      name: 'contentProvider',
      content: (supplierId ? supplier : metadata?.supplier) || DOUBLE_MINUS,
      hidden:
        !isMarketplaceOffer ||
        (offerType == 'awaitingApproval' && requiresApproval),
    },
    {
      name: 'contentProviderEmail',
      content: metadata?.supplierEmail ? (
        <a href={`mailto:${metadata.supplierEmail}`}>
          {metadata.supplierEmail}
        </a>
      ) : (
        DOUBLE_MINUS
      ),
      hidden:
        !isMarketplaceOffer ||
        (offerType == 'awaitingApproval' && requiresApproval),
    },
    {
      name: 'publisher',
      content: accountName || DOUBLE_MINUS,
      hidden: offerType !== 'awaitingApproval',
    },
    {
      name: 'publisherEmail',
      content: userEmail ? (
        <a href={`mailto:${userEmail}`}>{userEmail}</a>
      ) : (
        DOUBLE_MINUS
      ),
      hidden: offerType !== 'awaitingApproval',
    },
  ];

  return (
    <>
      <Descriptions column={2} colon={false} layout="vertical">
        {fields.map(
          ({ name, hidden, content, showTooltip }) =>
            !hidden && (
              <Descriptions.Item
                key={name}
                label={<FieldLabel {...{ name, showTooltip }} />}
              >
                <StyledSpan data-name={name}>{content}</StyledSpan>
              </Descriptions.Item>
            ),
        )}
      </Descriptions>

      <OfferTerms
        offerTerms={additionalTerms}
        visible={showOfferTerms}
        setVisible={setShowOfferTerms}
      />

      <LocationsDrawer
        offer={offer}
        visible={showOfferLocations}
        onClose={() => setShowOfferLocations(false)}
      />

      <MidsDrawer
        offerMids={offerMids}
        visible={showOfferMids}
        onClose={() => setShowOfferMids(false)}
      />

      <AvailableProgramsDrawer
        uniqueOffer={offer}
        visible={showAvailablePrograms}
        setVisible={setShowAvailablePrograms}
      />
    </>
  );
}
