import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Row, Checkbox, Col, Tooltip, InputNumber, Input } from 'antd';
import { Offer } from '@fidel.uk/types';
import Form from '../../../../../components/form';
import { CreateUpdateOfferFormModel } from '../../../types';
import { colors } from '../../../../../theme';
import { QuestionMark } from '../../../../../components/icons';
import { TooltipWrapper } from '../../styled';
import { Select } from '../../../../../components/select';
import { FieldWithCurrencyWrapper } from '../../../../../components/form/styled';
import regex from '../../../../../utils/regex';
import { currencyBySupportedCountries } from '../../../../../utils/countries';

type LimitPeriodUnitProps = {
  isUpdating: boolean;
  fieldKey: 'budget' | 'maxRedemptions';
};

export default function LimitPeriodUnit({
  isUpdating,
  fieldKey,
}: LimitPeriodUnitProps) {
  const { t } = useTranslation('offers');

  const { watch } = useFormContext<CreateUpdateOfferFormModel>();

  const unitOptions = Object.values(
    fieldKey === 'budget'
      ? Offer.OfferBudgetUnit
      : Offer.OfferMaxRedemptionsUnit,
  ) as (Offer.OfferBudgetUnit | Offer.OfferMaxRedemptionsUnit)[];

  const {
    control,
    trigger,
    resetField,
    formState: { errors },
  } = useFormContext<CreateUpdateOfferFormModel>();

  const isEnabled = watch(`${fieldKey}.enabled`);

  const period = watch(`${fieldKey}.period`);
  const unit = watch(`${fieldKey}.unit`);

  const country = watch('country');

  const validatePeriodField = useCallback(
    periodValue => {
      if (periodValue) {
        trigger(`${fieldKey}.unit`);
      } else {
        resetField(`${fieldKey}.period`);
      }
    },
    [resetField, fieldKey, trigger],
  );

  const validateUnitField = useCallback(
    unitValue => {
      if (unitValue) {
        trigger(`${fieldKey}.period`);
      } else {
        resetField(`${fieldKey}.unit`);
      }
    },
    [resetField, fieldKey, trigger],
  );

  useEffect(() => {
    validatePeriodField(period);
    validateUnitField(unit);
  }, [period, unit, validatePeriodField, validateUnitField]);

  const isBudget = fieldKey === 'budget';

  const limitFieldName = isBudget
    ? `${fieldKey}.limit`
    : `${fieldKey}.transactionsLimit`;

  return (
    <>
      <Form.ItemController
        errors={errors}
        controller={{
          name: `${fieldKey}.enabled`,
          disabled: isUpdating,
          render: ({ field: { value, onChange, ...rest } }) => (
            <Row>
              <Col>
                <Checkbox
                  {...rest}
                  onChange={({ target }) => onChange(target.checked)}
                  checked={value}
                  data-testid={fieldKey}
                  disabled={isUpdating}
                >
                  <Trans
                    i18nKey={`createUpdate.${fieldKey}.title`}
                    ns="offers"
                  />
                </Checkbox>
              </Col>

              <Col>
                <TooltipWrapper>
                  <Tooltip
                    title={t(`createUpdate.${fieldKey}.tooltip`)}
                    placement="bottomRight"
                  >
                    <QuestionMark />
                  </Tooltip>
                </TooltipWrapper>
              </Col>
            </Row>
          ),
          control,
        }}
      />
      {isEnabled ? (
        <Row
          style={{
            display: 'flex',
            marginTop: '0.5rem',
            ...(isBudget ? { rowGap: '1rem' } : {}),
          }}
        >
          <div style={{ width: '100%' }}>
            {isBudget ? (
              <FieldWithCurrencyWrapper>
                <Form.ItemController
                  label={
                    <span
                      style={{
                        fontSize: '14px',
                        letterSpacing: '0.3px',
                        color: colors.black,
                      }}
                    >
                      {t(`createUpdate.${fieldKey}.limit.label`)}
                    </span>
                  }
                  errors={errors}
                  controller={{
                    name: limitFieldName,
                    disabled: isUpdating,
                    render: ({ field }) => (
                      <Input
                        {...field}
                        min={1}
                        data-testid={limitFieldName}
                        placeholder={t('createUpdate.enterValue')}
                        disabled={isUpdating}
                        prefix={currencyBySupportedCountries[country] || '--'}
                      />
                    ),
                    rules: {
                      required: true,
                      validate: { numeric: value => regex.numeric.test(value) },
                    },
                    control,
                  }}
                />
              </FieldWithCurrencyWrapper>
            ) : (
              <Form.ItemController
                label={
                  <span
                    style={{
                      fontSize: '14px',
                      letterSpacing: '0.3px',
                      color: colors.black,
                    }}
                  >
                    {t(`createUpdate.${fieldKey}.limit.label`)}
                  </span>
                }
                errors={errors}
                controller={{
                  name: limitFieldName,
                  disabled: isUpdating,
                  render: ({ field }) => (
                    <InputNumber
                      {...field}
                      min={1}
                      data-testid={limitFieldName}
                      placeholder={t('createUpdate.enterValue')}
                      disabled={isUpdating}
                    />
                  ),
                  rules: {
                    min: 1,
                    required: true,
                  },
                  control,
                }}
              />
            )}
          </div>
          <div style={{ width: '100%', display: 'flex', gap: '1rem' }}>
            <Form.ItemController
              label={
                <span
                  style={{
                    fontSize: '14px',
                    letterSpacing: '0.3px',
                    color: colors.black,
                  }}
                >
                  {t('createUpdate.periodUnit.period.label')}
                </span>
              }
              errors={errors}
              controller={{
                name: `${fieldKey}.period`,
                render: ({ field }) => (
                  <InputNumber
                    {...field}
                    min={1}
                    data-testid="period"
                    placeholder={t('createUpdate.enterValue')}
                    disabled={isUpdating}
                  />
                ),
                disabled: isUpdating,
                rules: {
                  validate: {
                    min: value => (unit ? value >= 1 : true),
                  },
                  required: !!unit,
                },
                control,
              }}
            />
            <Form.ItemController
              label={t('createUpdate.periodUnit.unit.label')}
              style={{ width: '100%' }}
              errors={errors}
              data-testid="unit"
              controller={{
                name: `${fieldKey}.unit`,
                render: ({ field }) => (
                  <Select
                    {...field}
                    style={{ width: '100%' }}
                    disabled={isUpdating}
                    allowClear
                  >
                    {unitOptions.map(value => (
                      <Select.Option key={value} value={value}>
                        {t(`createUpdate.periodUnit.unit.${value}`)}
                      </Select.Option>
                    ))}
                  </Select>
                ),
                disabled: isUpdating,
                rules: {
                  required: !!period,
                },
                control,
              }}
            />
          </div>
        </Row>
      ) : (
        <>{/* we need an empty element that is not null, to satisfy antd */}</>
      )}
    </>
  );
}
