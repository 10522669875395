import { shallowEqual } from 'react-redux';
import moment from 'moment';
import { useAppSelector } from '../../../../store/hooks';
import {
  ContentProviderMetadata,
  Offer,
  OfferMetadata,
  UniqueOfferApprovalTypes,
} from '../../../../store/offers/offers-model';
import { CreateUpdateOfferFormModel } from '../../types';
import { weekDays } from '../../../../types';
import {
  omitPropertiesFromMetadata,
  transformOfferMetadata,
} from '../../utils';

export const DEFAULT_MAX_PAYMENT_TIMELINE = 30;

export function useOfferToFormModel(): (
  offer: Offer | undefined,
) => CreateUpdateOfferFormModel | null {
  const { accountId, isContentProvider } = useAppSelector(
    ({ account }) => ({
      accountId: account?.details?.id,
      isContentProvider: !!account.contentProviderInfo,
    }),
    shallowEqual,
  );

  return offer => {
    if (!offer) {
      return null;
    }

    const startDate = offer?.startDate ? moment(offer.startDate) : undefined;
    const endDate = offer?.endDate ? moment(offer.endDate) : undefined;

    let offerFormModel: CreateUpdateOfferFormModel = {
      activation: offer.activation?.enabled,
      qualifiedTransactionsLimit: offer.activation?.qualifiedTransactionsLimit,
      schemes: offer.schemes,
      brandId: offer.brandId,
      offerName: offer.name,
      country: offer.countryCode,
      discountAmount: (
        (offer.type?.value ?? 0) + (offer.feeSplit ?? 0)
      ).toString(),
      discountType: offer.type.name === 'discount' ? 'percentage' : 'exact',
      minSpend: offer.minTransactionAmount?.toString(),
      maxReward: offer.type.maxRewardAmount?.toString(),
      schedule: offer.daysOfWeek.map(idx => weekDays[idx]),
      returnDays: offer.returnPeriod?.toString() ?? '',
      startDate,
      startTime: moment(startDate, 'HH:mm:ss'),
      endDate,
      endTime: endDate && moment(endDate, 'HH:mm:ss'),
      terms: offer.additionalTerms,
      selfFunded:
        offer.funded?.type === 'card-linking' && offer.funded?.id === accountId,
      supplier: offer.supplier,
      metadata: recordToFieldArray(
        transformOfferMetadata(omitPropertiesFromMetadata(offer.metadata)) ||
          {},
      ),
      customiseFees: !!offer.fees,
      publisherFee: offer.fees && offer.fees.publisherFee,
      fidelFee: offer.fees && offer.fees.fidelFee,
      supplierInfo: recordToFieldArray(offer.supplierInfo || {}),
      maxRedemptions: { enabled: !!offer.maxRedemptions },
      budget: { enabled: !!offer.budget },
    };

    if (isContentProvider) {
      const cpMetadata = (offer.metadata?.contentProvider ??
        {}) as ContentProviderMetadata;

      offerFormModel = {
        ...offerFormModel,
        grossCommission: offer.type.value,
        maxPaymentTimeline:
          cpMetadata.payment?.maxTimeline ?? DEFAULT_MAX_PAYMENT_TIMELINE,
        approval: cpMetadata.approval?.type === UniqueOfferApprovalTypes.STA,
        customerRecencyEnabled: cpMetadata.customerRecency?.enabled,
        customerRecencyDays: cpMetadata.customerRecency?.days || 0,
        channel: cpMetadata.payment?.channel?.[0],
        longDescription: cpMetadata.description?.long,
        shortDescription: cpMetadata.description?.short,
      };
    }

    return offerFormModel;
  };
}

function recordToFieldArray(
  record: OfferMetadata | Record<string, string>,
): { key: string; value: string; id: string }[] {
  return Object.keys(record).map(recordKey => ({
    key: recordKey,
    value: record[recordKey],
    id: recordKey,
  })) as { key: string; value: string; id: string }[];
}
